<template>
  <div class="com-user-CardProduct">
    <img
      v-if="data.showType === 2"
      class="recommend"
      src="../../../../public/img/recommend.png"
    />
    <div class="d-flex align-items-center title">
      <img class="logo" src="../../../../public/img/pro-icon.png" />
      <span>{{ data.productName }}</span>
    </div>

    <div
      class="content"
      v-if="!(userInfo.accountType === 0 && data.showType === 1)"
    >
      <div>{{ data.showType === 2 ? "产品售价" : "有效期至/次数" }}</div>
      <p>
        <template v-if="data.showType === 2">
          <span class="icon">
            ¥<span class="price">{{ data.price }}</span>
          </span>
          <span> 起</span>
        </template>
        <template v-if="data.showType === 1">
          <span v-if="data.productType === 1">
            {{ data.validDate | date("YYYY-MM-DD") }}
          </span>
          <span v-if="data.productType === 2">{{ data.duramax }}</span>
        </template>
      </p>
    </div>

    <div class="d-flex align-items-center card-footer">
      <template v-if="data.showType === 2">
        <a-button
          class="left-but"
          type="primary"
          v-click.timeout="2"
          @click="buyClick"
        >
          立即购买<a-icon type="arrow-right" />
        </a-button>
      </template>
      <template v-else>
        <a-button
          class="left-but"
          type="primary"
          target="_blank"
          href="/ucs/home"
        >
          进入使用<a-icon type="arrow-right" />
        </a-button>

        <a-button
          class="right-but"
          type="primary"
          v-if="userInfo.accountType == 1"
          @click="buyClick"
        >
          续费<cs-icon style="margin-left: 10px" name="icon-xufei" />
        </a-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    data: {},
  },
  data() {
    return {
      source: {},
    };
  },
  computed: {
    ...mapState({ userInfo: (state) => state.store.userInfo }),
  },
  methods: {
    buyClick() {
      this.$router.push(`/product/addOrder?id=${this.data.productId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.com-user-CardProduct {
  width: 600px;
  height: 270px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position-x: right;
  background-color: white;
  background-image: url("../../../../public/img/card-bg.png");
  position: relative;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin-top: 10px;
  @media screen and (max-width: 1440px) {
    width: 490px;
  }
  .title {
    height: 60px;
    margin-left: 20px;
    font-family: PingFangSC-Medium;
    font-size: 22px;
    color: #1b2439;
    letter-spacing: 0;
    font-weight: bold;
    border-bottom: 1px solid #f1f1f1;
    width: 300px;
    .logo {
      width: 32px;
      height: 32px;
      margin-right: 20px;
    }
  }

  .content {
    margin-top: 40px;
    margin-left: 20px;
    div {
      font-size: 12px;
      color: #748296;
    }

    p {
      margin-top: 10px;
      font-family: PingFangSC-Regular;
      font-size: 22px;
      color: #374567;
      padding: 0;
      margin-bottom: 0;
      .price {
        font-family: DINCondensed-Bold;
        font-size: 46px;
        color: #ff5620;
        font-weight: bold;
      }
      .icon {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #ff5620;
      }
    }
  }

  .card-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 230px;
    width: auto;
  }

  .recommend {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 80px;
  }

  .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    .left-but {
      flex: 1;
      height: 100%;
      border-radius: 0;
      line-height: 50px;
      border: none;
    }

    .right-but {
      flex: 1;
      background: #f8c478;
      height: 100%;
      border-radius: 0;
      line-height: 50px;
      border: none;
      color: #4d342e;
    }
  }
}
</style>